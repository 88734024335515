import Paper from '@mui/material/Paper';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Autocomplete, Checkbox, Chip, Stack, TextField, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { checkNameBranch } from 'src/functions/expand-header';
import './index.scss';
import { branchCreate, branchData } from 'src/utils/app-data';
import SelectCustom from 'src/shared-components/SelectCustom';

// function MuiTableRow(props: any) {
//   const { row, length, selectedRow, deleteProduct, index, text, changeQuantity } = props;
//   const [open, setOpen] = useState<boolean>(false);
//   const [exampleState, setExampleState] = useState<string>(row?.quantity);
//   const onChange = (event: any) => {
//     // setExampleState(event?.target?.value);
//     text(event?.target?.value);
//   };
// console.log("texxt1111",text);
//   return (

//   );
// }

interface expandProps {
  columns: any;
  rows: any;
  deleteProduct?: any;
  checkboxSelection?: boolean;
  onRowClick?: (event: React.MouseEvent<unknown>, name: string) => void;
  handleClick?: any;
  loadOnServerSide?: boolean;
  onSelectAllClick?: () => void;
  handleSelectAllClick?: any;
  selected?: any;
  text?: any;
  changeText?: any;
  isChangeQuantity?: boolean;
}

function RefundTable(props: expandProps) {
  const {
    columns,
    rows,
    deleteProduct,
    checkboxSelection,
    onRowClick,
    handleClick,
    loadOnServerSide = false,
    selected = [],
    onSelectAllClick,
    handleSelectAllClick,
    text,
    changeText,
    isChangeQuantity = false,
  } = props;

  const renderRow = (row: any, column: any, rowsIndex: any) => {
    let params = {
      id: row?.id,
      row,
      column,
      rowsIndex,
    };
    let res = row?.[column.field];
    if (column?.renderCell) {
      res = column?.renderCell(params);
    }
    if (column.field === 'stt') {
      res = rowsIndex + 1;
    }
    return res;
  };
  const [rowsList, setRowsList] = useState<any>(rows);

  const [itemQuantity, setItemQuantity] = useState<any>();
  const isSelected = (name: string) => {
    const id = selected?.map((item: any) => item?.orderDetailId);
    return id?.indexOf(name) !== -1;
  };

  useEffect(() => {
    const itemQuantity = rows?.map((item: any) => {
      return { amount: item.quantity };
    });
    setItemQuantity(itemQuantity);
  }, []);

  return (
    <>
      <TableContainer component={Paper} className="expand-table-order tracking-table">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {checkboxSelection && (
                <TableCell align={'center'} style={{ width: '2rem' }}>
                  <Checkbox
                    color="primary"
                    indeterminate={selected && selected?.length > 0 && selected.length < rows.length}
                    checked={selected && rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell>
              )}
              {columns?.map((col: any, i: number) => {
                return (
                  <TableCell align={'center'} key={col.field} style={{ width: col.width }}>
                    {col?.headerName}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsList?.map((row: any, index: number) => {
              let error = row?.quantity == 0 && 'Số lượng';
              const isItemSelected = isSelected(row?.id);
              // TODO
              const onChange = (event: any, index1: number) => {
                let rowIndex = [...rowsList];
                if (Number(event.target.value) <= itemQuantity[index1]['amount']) {
                  rowsList[index1]['quantity'] = event.target.value ?? 1;
                  setRowsList(rowIndex);
                } else {
                  console.log('khong du so luong');
                }

                // rowsList[index1]['quantity'] = event.target.value;
                // setRowsList(rowIndex);
              };
              return (
                <>
                  <TableRow
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={checkboxSelection && isItemSelected}
                  >
                    {checkboxSelection && (
                      <TableCell
                        onClick={() => {
                          handleClick({
                            orderDetailId: row.id,
                            quantity: Number(row?.quantity),
                            price: Number(row?.price),
                          });
                        }}
                        style={{ width: '2rem', padding: '0px' }}
                        align={'center'}
                        padding={'none'}
                        className={'checkbox'}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': 'check-box-' + index,
                          }}
                        />
                      </TableCell>
                    )}
                    <>
                      <TableCell align="center" style={{ wordBreak: 'break-word' }}>
                        <Stack>
                          <Typography>{row?.name}</Typography>
                          <Chip
                            label={row?.skuSub}
                            size="small"
                            sx={{ fontSize: '12px', width: 'fit-content', margin: '0 auto', borderRadius: '4px' }}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell style={{ wordBreak: 'break-word' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {row?.productVariations?.map((varia: any, index: number) => {
                            if (!isEmpty(varia.value)) {
                              return (
                                <span key={index}>
                                  {varia?.name}: {varia?.value}
                                </span>
                              );
                            }
                          })}
                        </div>
                      </TableCell>
                      <TableCell align="center" style={{ wordBreak: 'break-word' }}>
                        <TextField
                          type="number"
                          value={row?.quantity}
                          onChange={e => {
                            onChange(e, index);
                            changeText(e);
                          }}
                          className="input-field font-size-1 text-color"
                          // multiline
                          // maxRows={3}
                          sx={{
                            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                              WebkitAppearance: 'none',
                              margin: 0,
                            },
                            '& .Mui-error': {
                              fontSize: '11px',
                            },
                          }}
                          placeholder={'0'}
                          onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                          inputProps={{ inputMode: 'numeric', pattern: '[1-9]*', min: 1 }}
                          helperText={error as string}
                          error={row.quantity <= 0}
                        />
                      </TableCell>
                    </>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default React.memo(RefundTable);
