import { Box, Checkbox, Pagination, TablePagination, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isEmpty } from 'lodash';
import * as React from 'react';
import noData from 'src/assets/no-data.png';
import './index.scss';
import { isMobile } from 'react-device-detect';

interface IDataTableProps {
  rows: any[];
  columns: any[];
  checkBoxAlignment: 'center' | 'left' | 'right';
  headerCheckBoxAlignment: 'center' | 'left' | 'right';
  checkboxSelection?: boolean;
  onSelectAllClick?: () => void;
  onRowClick?: (event: React.MouseEvent<unknown>, name: string) => void;
  onChangeRowsPerPage?: any;
  loadOnServerSide: boolean; // Using client function or server function
  selected?: any[];
  handleSelectAllClick?: () => void;
  handleClick?: () => void;
  handleChangePage?: any;
  page: number;
  pageSize: number;
  total: number;
  handleShowDetail: any;
  handleInPreview: any;
  isSell?: boolean;
  isPagination?: boolean;
}

export default function DataTableV2(props: IDataTableProps) {
  const {
    rows,
    columns,
    onSelectAllClick,
    onRowClick = (event: React.MouseEvent<unknown>, name: string) => {},
    onChangeRowsPerPage,
    checkboxSelection = false,
    checkBoxAlignment = 'center',
    headerCheckBoxAlignment = 'center',
    loadOnServerSide = false,
    selected = [],
    handleSelectAllClick,
    handleClick = (event: React.MouseEvent<unknown>, name: string) => {},
    handleChangePage,
    page,
    pageSize,
    total,
    handleShowDetail,
    handleInPreview,
    isSell = false,
    isPagination = true,
  } = props;
  const renderRow = (row: any, column: any, rowsIndex: any) => {
    let params = {
      id: row?.id,
      row,
      column,
      rowsIndex,
    };
    let res = row?.[column?.field];
    if (column?.renderCell) {
      res = column?.renderCell(params);
    }
    if (column?.field === 'stt') {
      res = rowsIndex + 1;
    }

    return res;
  };

  const isSelected = (name: string) => {
    return selected?.indexOf(name) !== -1;
  };

  const handleRow = (column: any, row: any) => {
    handleShowDetail(row.id);
  };

  return (
    <TableContainer className="datatable-v2" component={Paper}>
      <Table sx={{ minWidth: 1050 }} size="small" aria-label="a dense table" padding="none">
        <TableHead>
          <TableRow>
            {checkboxSelection && (
              <TableCell align={headerCheckBoxAlignment} padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={selected && selected?.length > 0 && selected.length < rows.length}
                  checked={selected && rows.length > 0 && selected.length === rows.length}
                  onChange={loadOnServerSide ? onSelectAllClick : handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                  size="small"
                />
              </TableCell>
            )}
            {columns?.map(header => (
              <TableCell key={header.field} width={header?.flex || header?.width} align={header.headerAlign}>
                {header.headerName}
              </TableCell>
            ))}
            {isSell && (
              <TableCell align={headerCheckBoxAlignment} padding="checkbox">
                Bán hàng
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row: any, index: number) => {
            const isItemSelected = isSelected(row.id);
            return (
              <TableRow
                key={index}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                selected={checkboxSelection && isItemSelected}
              >
                {checkboxSelection && (
                  <TableCell
                    align={checkBoxAlignment}
                    onClick={(event: any) => {
                      loadOnServerSide ? onRowClick(event, row.id) : handleClick(event, row.id);
                    }}
                  >
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': 'check-box-' + index,
                      }}
                    />
                  </TableCell>
                )}
                {columns?.map((column: any, index1: number) => (
                  <TableCell
                    width={column.flex}
                    align={column.headerAlign}
                    key={column.headerName}
                    onClick={() => handleRow(column, row)}
                  >
                    {renderRow(row, column, index)}
                  </TableCell>
                ))}
                {isSell && (
                  <TableCell
                    align={checkBoxAlignment}
                    onClick={(event: any) => {
                      handleInPreview(row);
                    }}
                    width={50}
                  >
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={!row?.inPreview}
                      inputProps={{
                        'aria-labelledby': 'check-box-' + index,
                      }}
                    />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {isEmpty(rows) && (
        <div className={'btn-add-product'}>
          <div className={'view-btn-add'}>
            <img src={noData} className="empty-data" />
            <span>Không có sản phẩm</span>
          </div>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <p>Chuyển nhanh đến trang: </p>

        <input
          value={page + 1}
          min={1}
          onChange={event => {
            handleChangePage(event, Number(event.target.value) - 1);
          }}
          style={{ width: 50, marginLeft: 20, textAlign: 'center' }}
        />
        <p style={{ padding: '0px 12px' }}>Tổng số trang: {Math.ceil(total / pageSize)}</p>
      </div>

      {isPagination && (
        <>
          <TablePagination
            className="isDesktop"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={total || 0}
            rowsPerPage={pageSize || 0}
            page={page || 0}
            onPageChange={handleChangePage}
            labelRowsPerPage={'Số hàng hiển thị'}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
          <Pagination
            className="isMobile pagination-table-ui"
            count={total || 0}
            defaultPage={0}
            siblingCount={0}
            page={page + 1 || 1}
            onChange={(e, newPage) => handleChangePage(e, newPage - 1)}
          />
        </>
      )}
    </TableContainer>
  );
}
