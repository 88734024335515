import { Box, Grid, IconButton, Popover, Slider, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { convertCurrentcy, convertDigitalNumber } from 'src/utils/utils';
import './SliderPrice.scss';

type Props = {
  onSliderChange: (price_min: number, price_max: number) => void
};

const slider_label = (value: number, _: any) => {
  return convertDigitalNumber(value, 2);
};

const SliderPrice = ({ onSliderChange }: Props) => {
  const [popover, setPopover] = useState<{ open: boolean, ref: HTMLButtonElement | null }>({ open: false, ref: null });
  const [value, setValue] = useState<number[]>([0, 100000000])
  const [range, setRange] = useState<number[]>([0, 100000000])
  const [rangeTMP, setRangeTMP] = useState<number[]>(range)
  const resetRangeCB = useRef<(() => void) | null>(null)
  const [error, setError] = useState<{ range: boolean, outside: string }>({ outside: '', range: false })
  const onSliderChangeRef = useRef<NodeJS.Timeout | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopover({ open: true, ref: event.currentTarget })
  };

  const handleClose = () => {
    setPopover({ open: false, ref: null })
  };

  const marks = useMemo(() => {
    const distance = range[1] / 10
    return Array(11).fill(distance).reduce<{ value: number, label: string }[]>((acum, item, idx) => {
      if (!idx) {
        acum.push({ value: 0, label: '0' })
      } else {
        let tmp = item * idx
        acum.push({ value: tmp, label: convertDigitalNumber(tmp, 2) })
      }
      return acum
    }, [])
  }, [range])

  const handleSliderChange = (_: any, newValue: number | number[], activeThumb: number) => {
    if (Array.isArray(newValue)) {
      setValue(pre => {
        pre[activeThumb] = newValue[activeThumb];
        return [...pre];
      });
    }
  };

  const onChangeRangeFilter = (activeThumb: any) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value = e.target.value.replace(/\D/img, '') || 0
    setRangeTMP(pre => {
      pre[activeThumb] = +value
      if (pre[0] >= pre[1]) {
        setError(pre => ({...pre, range: true }))
      } else {
        error && setError(pre => ({ ...pre, range: false }))
      }
      return [...pre]
    })
  }

  const onChangeValueOutSide = (activeThumb: any) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value = e.target.value.replace(/\D/img, '') || 0
    setValue(pre => {
      pre[activeThumb] = +value
      if (pre[0] >= pre[1]) {
        setError(pre => ({ ...pre, outside: 'Giá bắt đầu phải lớn hơn giá kết thúc' }))
      } else if (pre[0] < range[0] || pre[1] > range[1]) {
        setError(pre => ({ ...pre, outside: 'Giá nhập vào sai so với range điều kiện lọc' }))
      } else {
        error && setError(pre => ({ ...pre, outside: '' }))
      }
      return [...pre]
    })
  }

  useEffect(() => {
    resetRangeCB.current = () => {
      setRange(rangeTMP)
    }
    document.addEventListener('focusout', resetRangeCB.current)

    return () => {
      resetRangeCB.current && document.removeEventListener('focusout', resetRangeCB.current)
    }
  }, [rangeTMP])

  useEffect(() => {
    let isNoResetValue = value[0] >= range[0] && value[1] <= range[1] && value[0] < value[1]
    if (!isNoResetValue) {
      setValue([Math.max(value[0], range[0]), Math.min(value[1], range[1])])
    }
  }, [range])

  useEffect(() => {
    onSliderChangeRef.current = setTimeout(() => {
      onSliderChange(value[0], value[1])
    }, 800)
    return () => {
      onSliderChangeRef.current && clearTimeout(onSliderChangeRef.current)
    }
  }, [value])

  return (
    <Stack className="slider-container">
      <Stack direction={'row'}>
        <Box className="slider-text">
          <TextField name="start" id="start" placeholder="Giá nhỏ nhất" variant="outlined"
            value={convertCurrentcy(value[0])} onChange={onChangeValueOutSide(0)} />
          <TextField name="end" id="end" placeholder="Giá lớn nhất" variant="outlined"
            value={convertCurrentcy(value[1])} onChange={onChangeValueOutSide(1)} />
        </Box>
        <IconButton aria-describedby="slider-price-trigger" onClick={handleClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="black"
            className="slider-icon"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </IconButton>
      </Stack>
      {error.outside && <span style={{ fontSize: '12px', color: 'red' }}>{error.outside}</span>}
      <div>
        <Popover
          id="slider-price-trigger"
          open={popover.open}
          anchorEl={popover.ref}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box
            sx={{
              width: 600,
              height: 200,
              borderRadius: 1,
              padding: '10px 20px'
            }}
          >
            <Grid direction={'column'} marginBottom={5}>
              <Typography>Giới hạn khoảng giá tìm kiếm</Typography>
              <Box className="slider-text __start">
                <TextField name="start" id="start" placeholder="Giá bắt đầu" variant="outlined"
                  value={convertCurrentcy(rangeTMP[0])} onChange={onChangeRangeFilter(0)} />
                <TextField name="end" id="end" placeholder="Giá kết thúc" variant="outlined" 
                  value={convertCurrentcy(rangeTMP[1])} onChange={onChangeRangeFilter(1)} />
              </Box>
              {error.range && <span style={{ fontSize: '12px', color: 'red' }}>Giá bắt đầu phải nhỏ hơn giá kết thúc</span>}
            </Grid>
            <Slider
              aria-label="Restricted values"
              value={value}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              disableSwap
              min={range[0]}
              max={range[1]}
              step={10000}
              marks={marks}
              valueLabelFormat={slider_label}
            />
          </Box>
        </Popover>
      </div>
    </Stack>
  );
};

export default SliderPrice;
