import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import errorImg from 'src/assets/img/error-img.png';
import { checkNameBranch } from 'src/functions/expand-header';
import { rejectRefund } from 'src/services/stock.services';
import DataTableV2 from 'src/shared-components/DataTableV2';
import { convertCurrency } from 'src/utils/utils';
import './index.scss';

const RefundList = (props: any) => {
  const {
    data,
    selectionModel,
    handleSelectionModeChange,
    handleShowDetail,
    handleClick,
    onChangeRowsPerPage,
    handleChangePage,
    total,
    page,
    handleInPreview,
    pageSize,
    onApprove,
    refetchRefund,
  } = props;
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openRefund, setOpenRefund] = useState<boolean>(false);

  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const columns: any = [
    {
      field: 'stt',
      headerName: 'STT',
      flex: 40,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'branch',
      headerName: 'Chi nhánh',
      flex: 90,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return <div>{checkNameBranch(params?.row?.branch)?.branch}</div>;
      },
    },
    {
      field: 'orderNumber',
      flex: 100,
      headerName: 'Mã đơn stock',
      headerAlign: 'center',
      headerClassName: 'theme',
      renderCell: (params: any) => {
        return (
          <Link to={`/order/detail/${params?.row.orderId}`} target="_blank" style={{ color: 'rgb(26, 137, 172)' }}>
            {params?.row?.orderNumber}
          </Link>
        );
      },
    },
    {
      field: 'image',
      headerName: 'Ảnh stock',
      flex: 70,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              src={params.row.image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = errorImg;
              }}
              alt=""
              style={{ aspectRatio: 1, width: '60px', backgroundSize: 'cover' }}
            />
          </div>
        );
      },
    },
    {
      field: 'productName',
      headerName: 'Tên sản phẩm',
      flex: 140,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div>
            <div>{params?.row?.productName}</div>
            <div style={{ fontSize: '12px', color: '#b3b3b3' }}>{`(${params?.row?.skuSub})`}</div>
          </div>
        );
      },
    },
    {
      field: 'variations',
      headerName: 'Đặc tính',
      flex: 120,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div>
            {params?.row?.productVariations.map((item: any, index: number) => {
              return (
                <div>
                  <span key={index} style={{ fontWeight: '600' }}>
                    {item.name}
                  </span>
                  <span key={index}>: {item.value}</span>
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      field: 'price',
      headerName: 'Giá bán',
      flex: 100,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return <div>{convertCurrency(params?.row?.price)}</div>;
      },
    },
    {
      field: 'quantity',
      headerName: 'Số lượng',
      flex: 100,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'staffName',
      headerName: 'Nhân viên',
      flex: 100,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'note',
      headerName: 'Ghi chú',
      flex: 120,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
    },
    {
      field: 'action',
      headerName: 'Nhận hàng',
      flex: 80,
      headerAlign: 'center',
      headerClassName: 'theme',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Stack style={{ padding: '10px' }}>
            <Button
              style={{
                border: '1px solid #1797be',
                padding: '0.2rem 0.7rem',
                marginBottom: '10px',
              }}
              onClick={() => setOpenConfirm(true)}
              size="small"
            >
              <span style={{ color: '#1797be', textTransform: 'none' }}>Nhập kho</span>
            </Button>
            <Dialog
              open={openConfirm}
              onClose={() => setOpenConfirm(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" fontWeight={600}>
                Nhập kho
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">Bạn có chắc chắn muốn nhập kho?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={() => setOpenConfirm(false)}>
                  Hủy
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    onApprove(params?.row?.id, params?.row?.branch, user?.userName);
                    setTimeout(() => {
                      setOpenConfirm(false);
                    }, 500);
                  }}
                  autoFocus
                >
                  Xác nhận
                </Button>
              </DialogActions>
            </Dialog>
            <Button
              style={{
                padding: '0.2rem 0.7rem',
              }}
              onClick={() => setOpenRefund(true)}
              size="small"
              color="warning"
              variant="outlined"
            >
              Cancel
            </Button>
            <Dialog
              open={openRefund}
              onClose={() => setOpenConfirm(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" fontWeight={600}>
                Hủy hoàn hàng
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Bạn có chắc chắn muốn hủy hoàn hàng?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={() => setOpenRefund(false)}>
                  Hủy
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    onRefund(params?.id);
                    setTimeout(() => {
                      setOpenRefund(false);
                    }, 500);
                  }}
                  autoFocus
                >
                  Xác nhận
                </Button>
              </DialogActions>
            </Dialog>
          </Stack>
        );
      },
    },
  ];

  const rejectRefundMutation = useMutation({
    mutationFn: rejectRefund({ user: user.name }),
    onSuccess: () => {
      toast.success('Hủy hoàn hàng thành công');
      refetchRefund()
    },
    onError: (error: AxiosError<{ message: string }>) => {
      toast.error(error.response?.data?.message || 'Hủy hoàn hàng thất bại');
    },
  });

  const onRefund = (shippingId: string) => rejectRefundMutation.mutate(shippingId);

  return (
    <DataTableV2
      rows={data}
      columns={columns}
      {...props}
      handleSelectAllClick={handleSelectionModeChange}
      handleClick={handleClick}
      selected={selectionModel}
      handleShowDetail={handleShowDetail}
      handleChangePage={handleChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      total={total}
      page={page}
      pageSize={pageSize}
      handleInPreview={handleInPreview}
    />
  );
};

export default RefundList;
