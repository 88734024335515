import clientRequest from 'src/config/axios';
import { IStock } from 'src/model/sku.modal';

export const getProduct = async (params: any) => {
  const res = await clientRequest.get(`/product`, {
    params: {
      ...params,
    },
  });
  return res;
};
export const getProductDetail = async (params: any) => {
  const res = await clientRequest.get(`product/${params}`);
  return res;
};

export const getProductList = async (params: any) => {
  const res = await clientRequest.get(`/product/list`, {
    params: {
      ...params,
    },
  });
  return res;
};

export const getSearchSku = async (params: any) => {
  const res = await clientRequest.get(`product/searchsku`, {
    params: {
      ...params,
    },
  });
  return res;
};

export const createProduct = async (params: IStock) => {
  const res = await clientRequest.post(`product`, {
    ...params,
  });
  return res;
};

export const updateNoteSkuProduct = async (params: any) => {
  const { skuProductId, note } = params;
  const res = await clientRequest.put(`skuproduct/${skuProductId}/note`, {
    note,
  });
  return res;
};

export const updateDiscount = async (params: any) => {
  const res = await clientRequest.post(`product/discount`, {
    ...params,
  });
  return res;
};

export const checkWareHouse = async (params: any) => {
  const res = await clientRequest.post(`product/warehousecheck`, {
    ...params,
  });
  return res;
};

export const updateProduct = async (params: any) => {
  const res = await clientRequest.put(`product/${params.id}`, {
    ...params,
  });
  return res;
};

export const deleteProduct = async (params: any) => {
  const res = await clientRequest.delete(`productsku`, {
    data: {
      ...params,
    },
  });
  return res;
};

export const updateProductSku = async (params: any) => {
  const res = await clientRequest.put(`productsku/${params.id}`, {
    ...params,
  });
  return res;
};

export const downloadExcel = async (params: any) => {
  const res = await clientRequest.get(`export/product`, {
    responseType: 'blob',
    params: {
      ...params,
    },
  });
  return res;
};

export const rejectRefund = (body: any) => async (shippingId: string) => {
  return await clientRequest.post(`refund/${shippingId}/reject`, body);
};
