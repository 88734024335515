import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Divider, Grid, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useMutation, useQuery } from '@tanstack/react-query';
import fileDownload from 'js-file-download';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { isMobile } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';
import { getBrand, getSupplier, getType } from 'src/services/app.services';
import { approveRefund, getRefund } from 'src/services/order.servies';
import { getDetailSku } from 'src/services/sku.services';
import {
  checkWareHouse,
  downloadExcel,
  getProduct,
  getProductDetail,
  updateDiscount,
  updateNoteSkuProduct,
} from 'src/services/stock.services';
import styles from 'src/shared-components/DateRangePicker/calendar-custom.module.scss';
import FormControlCustom from 'src/shared-components/FormControlCustom';
import ModalAlert from 'src/shared-components/Modal';
import CollapsibleTable from 'src/shared-components/MuiDataTable';
import SearchInput from 'src/shared-components/SearchInput/Index';
import SelectCustom from 'src/shared-components/SelectCustom';
import SnackBarCustom from 'src/shared-components/Snackbar';
import { useToast } from 'src/shared-components/Toast';
import useDebounce from 'src/shared-components/debounce';
import { columns, filterDateTime, status, timeInventory, columnsNoPermission } from 'src/utils/app-data';
import { currencyFormat, formatDate } from 'src/utils/utils';
import SKUCreateAndEdit from '../sku/components/skuCreateAndEdit';
import CreateStock from './components/createStock';
import RefundList from './components/refundList';
import './stockManager.scss';
import { useNavigate } from 'react-router-dom';
import SliderPrice from 'src/shared-components/SliderPrice/SliderPrice';

let totalPrice: any;
const StockManager = () => {
  const toast = useToast();
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageRefund, setPageRefund] = useState<number>(0);
  const [pageSizeRefund, setPageSizeRefund] = useState<number>(10);
  const [showDrawer, setShowDrawer] = useState(false);
  const [skuId, setSKUId] = useState('');
  const [showSKUDrawer, setShowSKUDrawer] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValueChange] = useState<any>(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [selectedIdChildRow, setSelectedIdChildRow] = useState<any>([]);
  const [perDiscount, setPerDiscount] = useState<any>('');
  const [filterParams, setFilterParams] = useState<any>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [formType, setFormType] = useState<string>('');
  const [idProduct, setIdProduct] = useState<any>('');
  const [selectedRowData, setSelectedRowData] = useState<any>();
  const [skuIdProduct, setSkuProductId] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [sort, setSort] = useState<any>('Sắp xếp theo');
  const [valueSort, setValueSort] = useState<any>('NEW_PRRODUCT');
  const [selectedDate, setSelectedDate] = useState<any>({
    dateFrom: null,
    dateTo: null,
  });
  const [anchorElDateRange, setAnchorElDateRange] = useState<boolean>(false);
  const [screenWidth, setScreenWidth] = useState<any>(1100);
  const [price, setPrice] = useState<{ price_min: number, price_max: number }>({ price_min: 0, price_max: 0 })

  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const debouncedSearch = useDebounce(searchValue, 500);
  const isMenuOpen = Boolean(anchorEl);

  const detailProduct = useQuery(['productDetail', idProduct], () => getProductDetail(idProduct), {
    keepPreviousData: true,
    enabled: showDrawer,
  });

  const { data: dataProduct, refetch } = useQuery(
    ['productStock', { ...filterParams, ...price, page, pageSize }],
    () => getProduct({ ...filterParams, ...price, page: page + 1, pageSize: pageSize }),
    {
      enabled: true,
    },
  );

  const { data: dataRefund, refetch: refetchRefund } = useQuery(
    ['refund', { pageRefund, pageSizeRefund }],
    () => getRefund({ page: page + 1, pageSize: pageSize }),
    { enabled: true },
  );

  const { data: dataType } = useQuery(['type'], () => getType({}), { enabled: true });

  const defaultValues = {
    brand: null,
    status: status[2],
    supplier: null,
    outOfDate: null,
    branch: null,
    type: null,
    time: '',
  };

  const methods = useForm({
    defaultValues,
  });

  const { watch, setValue } = methods;
  const [textNote, setTextNote] = useState<string>(selectedRow?.note);

  const watchStatus: any = watch('status');
  const watchBrand: any = watch('brand');
  const watchSupplier: any = watch('supplier');
  const watchOutOfDate: any = watch('outOfDate');
  const watchBranch: any = watch('branch');
  const watchType: any = watch('type');
  const watchTime: any = watch('time');

  const { data: dataBrand } = useQuery(['dataBrand'], () => getBrand({}), { enabled: true, keepPreviousData: true });
  const dataSupplier = useQuery(['dataSupplier', dataProduct], () => getSupplier({}), {
    enabled: true,
    keepPreviousData: true,
  });

  const mutationUpdateNote = useMutation({
    mutationFn: updateNoteSkuProduct,
    onSuccess: (data: any) => {
      toast('success', 'Chỉnh sửa thành công');
      refetch();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  const mutationDiscount = useMutation({
    mutationFn: updateDiscount,
    onSuccess: (data: any) => {
      toast('success', 'Chỉnh sửa thành công');
      setOpenModal(false);
      refetch();
      setSelectedIdChildRow([]);
      setPerDiscount('');
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message[0]);
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  const mutationCheckHouse = useMutation({
    mutationFn: checkWareHouse,
    onSuccess: (data: any) => {
      toast('success', 'Kiểm kho thành công');
      setOpenModal(false);
      refetch();
      setSelectedIdChildRow([]);
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  const mutationApprove = useMutation({
    mutationFn: approveRefund,
    onSuccess: (data: any) => {
      toast('success', 'Xác nhận thành công');
      refetchRefund();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  const mutationExportExcel = useMutation({
    mutationFn: downloadExcel,
    onSuccess: (data: any) => {
      const fileName = 'DS_San_Pham' + new Date().toISOString() + '.xlsx';
      fileDownload(data.data, fileName);
      toast('success', 'Tải xuống thành công');
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
  });

  function getFirstDayOfWeek(d: any) {
    const date = new Date(d);
    const day = date.getDay(); // 👉️ get day of week

    const diff = date.getDate() - day + (day === 0 ? -6 : 1);

    return new Date(date.setDate(diff));
  }

  useEffect(() => {
    if (!showDrawer) {
      setIdProduct('');
      setFormType('');
    }
  }, [showDrawer]);

  useEffect(() => {
    let branchCode: any = 'ALL';
    if (value === 2) {
      branchCode = 'HOCHIMINH';
    } else if (value === 1) {
      branchCode = 'HANOI';
    } else {
      branchCode = 'ALL';
    }
    const params = {
      status: watchStatus?.codeName,
      outOfDate: watchOutOfDate?.codeName,
      brand: watchBrand?.id,
      branch: branchCode,
      supplier: watchSupplier,
      type: watchType?.code,
      pageSize: pageSize,
      page: page + 1,
      key: debouncedSearch,
      sort: valueSort,
      order: valueSort === 'PRICE' ? (sort === 'Giá giảm dần' ? 'DESC' : 'ASC') : null,
      startDateInHouse: selectedDate?.dateFrom?.getTime(),
      endDateInHouse: selectedDate?.dateTo?.getTime(),
    };

    setFilterParams(params);
  }, [
    watchStatus,
    watchOutOfDate,
    watchBrand,
    watchSupplier,
    value,
    watchType,
    valueSort,
    selectedDate?.dateFrom,
    selectedDate.fromTo,
    sort,
  ]);

  useEffect(() => {
    setFilterParams({ ...filterParams, key: debouncedSearch });
  }, [debouncedSearch]);

  useEffect(() => {
    if (watchTime?.code === 'option') {
      setAnchorElDateRange(true);
    } else {
      setAnchorElDateRange(false);
    }
  }, [JSON.stringify(watchTime)]);

  useEffect(() => {
    const now = new Date();

    if (watchTime?.code === 'month') {
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      lastDay.setUTCHours(16);
      lastDay.setUTCMinutes(59);
      lastDay.setUTCSeconds(59);

      setSelectedDate({ ...selectedDate, dateFrom: firstDay, dateTo: lastDay });
    } else if (watchTime?.code === 'week') {
      const firstDay = getFirstDayOfWeek(now);
      firstDay.setUTCHours(-7);
      firstDay.setUTCMinutes(0);
      firstDay.setUTCSeconds(0);
      const lastDay = new Date(firstDay);

      lastDay.setDate(lastDay.getDate() + 7);
      lastDay.setUTCHours(16);
      lastDay.setUTCMinutes(59);
      lastDay.setUTCSeconds(59);

      setSelectedDate({ ...selectedDate, dateFrom: firstDay, dateTo: lastDay });
    } else if (watchTime?.code === 'option') {
      // setSelectedDate({ ...selectedDate, dateFrom: '', dateTo: '' });
    } else if (watchTime?.code === 'ALL') {
      const currentYear = new Date().getFullYear();
      const firstDay = new Date(currentYear, 0, 1);
      const lastDay = new Date(currentYear, 11, 31);
      lastDay.setUTCHours(16);
      lastDay.setUTCMinutes(59);
      lastDay.setUTCSeconds(59);

      setSelectedDate({ ...selectedDate, dateFrom: firstDay, dateTo: lastDay });
    } else if (isEmpty(watchTime)) {
      setSelectedDate({ ...selectedDate, dateFrom: undefined, dateTo: undefined });
    }
  }, [watchTime]);

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  const detailSku = useQuery(['detailSku', skuId], () => getDetailSku(skuId), {
    // keepPreviousData: true,
    enabled: showSKUDrawer,
    staleTime: 5000,
  });

  const handelShowUpdateSku = (id: any) => {
    // navigate(`/sku-manager`, { state: { id } });

    setSKUId(id);

    // const ttt = await getDetailSku(id)

    setShowSKUDrawer(true);
    // console.log("id",dataProduct?.data?.data);
  };
  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      navigate('/login');
    }
    setSearchValue('');
  }, []);

  useEffect(() => {
    if (!isEmpty(dataProduct?.data?.data)) {
      totalPrice = dataProduct?.data?.data?.map((item: any) => item?.totalPrice)?.reduce((a: any, b: any) => a + b);
    } else {
      totalPrice = '';
    }
  }, [dataProduct?.data.data]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onUpdateStock = (id: string) => {
    setShowDrawer(true);
    setIdProduct(id);
    setFormType('update');
  };

  const handleOpenModal = () => {
    if (!isEmpty(selectedIdChildRow)) {
      setOpenModal(true);
    } else {
      toast('warning', 'Vui lòng chọn sản phẩm');
    }
  };

  const exportExcel = () => {
    mutationExportExcel.mutate({ ...filterParams });
  };

  const onApproveRefund = (id: string, branch = '', user: string) => {
    const params = {
      id: id,
      branch: branch,
      user: user,
    };
    mutationApprove.mutate(params);
  };

  const onConfirmDiscount = () => {
    const params = {
      ids: selectedIdChildRow,
      discountPercent: Number(perDiscount),
    };
    mutationDiscount.mutate(params);
  };

  const onCheckWareHouse = () => {
    const params = {
      ids: selectedIdChildRow,
    };
    if (!isEmpty(selectedIdChildRow)) {
      mutationCheckHouse.mutate(params);
    } else {
      toast('warning', 'Vui lòng chọn sản phẩm');
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValueChange(newValue);
  };

  const handleSelectRow = (row: any) => {
    setSelectedRow(row);
  };

  const onSelectedChildRow = (row: any) => {
    const checkExit = selectedIdChildRow?.find((item: any) => {
      return item === row.id;
    });
    if (!isEmpty(checkExit)) {
      const existRow = selectedIdChildRow?.filter((item: any) => item !== row?.id);
      setSelectedIdChildRow(existRow);
      setSelectedRowData(existRow);
      setSkuProductId(row.SKUProductId);
    } else {
      setSelectedIdChildRow([...selectedIdChildRow, row?.id]);
      setSelectedRowData([...selectedIdChildRow, row]);
      setSkuProductId(row.SKUProductId);
    }
  };

  const handleSelectAllClick = (item: any, event: React.ChangeEvent<HTMLInputElement>) => {
    if (isEmpty(selectedIdChildRow)) {
      const newSelected = item.map((n: any) => n.id);
      setSelectedIdChildRow(newSelected);
      setSelectedRowData(newSelected);
      setSkuProductId(item?.[0]?.SKUProductId);
      return;
    }

    setSelectedIdChildRow([]);
    setSelectedRowData([]);
    setSkuProductId('');
  };

  const onChangeText = (text: any) => {
    setTextNote(text);
  };

  const handleChangeDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerDiscount(event.target.value);
  };

  const handleChangePage = (event: any, value: any) => {
    setPage(value);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
  };

  const handleChangePageRefund = (event: any, value: any) => {
    setPageRefund(value);
  };
  const handleChangeRowsPerPageRefund = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSizeRefund(+event.target.value);
  };

  const onEditNote = (id: any) => {
    const params = {
      skuProductId: id,
      note: textNote,
    };
    mutationUpdateNote.mutate(params);
  };

  const buildTab = () => {
    return (
      <Grid container overflow={'auto'}>
        <Grid flexDirection={{ xs: 'column', md: 'row' }} md={7} item>
          <Tabs value={value} onChange={handleChange} textColor="primary">
            <Tab label="Tất cả" className="left-border" />
            <Tab label="Hà Nội" className="mid-border" />
            <Tab label="Hồ Chí Minh" className="right-border" />
            <Tab label="Hàng hoàn" className="right-border" />
          </Tabs>
        </Grid>
      </Grid>
    );
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onChoose = (text: any) => {
    switch (text) {
      case 'Hàng mới':
        setValueSort('NEW_PRODUCT');
        break;
      case 'Giá tăng dần':
        setValueSort('PRICE');
        break;
      case 'Giá giảm dần':
        setValueSort('PRICE');
        break;
      case 'Giá giảm nhiều nhất':
        setValueSort('BEST_SALES');
        break;
      case 'Số lượng tăng dần':
        setValueSort('INCREASING');
        break;
      case 'Số lượng giảm dần':
        setValueSort('DECRESING');
        break;
      default:
        break;
    }
    setSort(text);
    handleMenuClose();
  };

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={'icon-profile'}
    >
      <MenuItem disabled>Sắp xếp theo</MenuItem>
      <MenuItem onClick={() => onChoose('Hàng mới')}>Hàng mới</MenuItem>
      <MenuItem onClick={() => onChoose('Giá tăng dần')}>Giá tăng dần</MenuItem>
      <MenuItem onClick={() => onChoose('Giá giảm dần')}>Giá giảm dần</MenuItem>
      <MenuItem onClick={() => onChoose('Giá giảm nhiều nhất')}>Giá giảm nhiều nhất</MenuItem>
      <MenuItem onClick={() => onChoose('Số lượng tăng dần')}>Số lượng tăng dần</MenuItem>
      <MenuItem onClick={() => onChoose('Số lượng giảm dần')}>Số lượng giảm dần</MenuItem>
    </Menu>
  );
  const dateNow: any = Date.now();

  const changeDate = (e: any) => {
    // selectedDate(e);
    const setDate = e[1];
    setDate.setUTCHours(16);
    setDate.setUTCMinutes(59);
    setDate.setUTCSeconds(59);

    setSelectedDate({ ...selectedDate, dateFrom: e[0], dateTo: e[1] });
    const dateFrom = formatDate(e[0], false);
    const dateTo = formatDate(e[1], false);
    setValue('time', `${dateFrom} - ${dateTo}`);

    setAnchorElDateRange(false);
  };

  const onSliderChange = (price_min: number, price_max: number) => {
    setPrice({ price_max, price_min })
    setFilterParams((pre: any) => ({ ...pre, price_min, price_max }))
  }

  window.onresize = function () {
    setScreenWidth(document.documentElement.clientWidth);
  };
  return (
    <div className={'page-layout'}>
      <Grid container className={'align-items-center header_title'}>
        <Grid item md={7}>
          <h2 className={'txt-title'}>QUẢN LÝ HÀNG STOCK</h2>
        </Grid>
        <Grid item md={5}>
          <Box className={'header-button'}>
            <Button className={'btn-create'} onClick={onCheckWareHouse}>
              <span className={'txt'}>Kiểm kho</span>
            </Button>
            <Button className={'btn-create'} onClick={handleOpenModal}>
              <span className={'txt'}>Giảm giá</span>
            </Button>
            <Button className={'btn-create'} onClick={exportExcel}>
              <span className={'txt'}>In danh sách stock</span>
            </Button>
            <Button className={'btn-create'} onClick={() => setShowDrawer(true)}>
              <span className={'txt'}>Nhập stock</span>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <FormProvider {...methods}>
        <Grid className="search" container gap={2}>
          <Grid item md={8}>
            <Box
              style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              <FormControlCustom label="Tình trạng" fullWidth>
                <div className="view-input">
                  <SelectCustom options={status} placeholder={'Tất cả'} name={'status'} />
                </div>
              </FormControlCustom>
              <FormControlCustom label="Thời gian tồn kho" fullWidth>
                <div className="view-input">
                  <SelectCustom options={timeInventory} placeholder={'Tất cả'} name={'outOfDate'} />
                </div>
              </FormControlCustom>
              <FormControlCustom label="Thương hiệu" fullWidth>
                <div className="view-input">
                  <SelectCustom options={dataBrand?.data} placeholder={'Tất cả'} name={'brand'} />
                </div>
              </FormControlCustom>
              <FormControlCustom label="Nhà cung cấp" fullWidth>
                <div className="view-input">
                  <SelectCustom options={dataSupplier?.data?.data} placeholder={'Tất cả'} name={'supplier'} />
                </div>
              </FormControlCustom>
              <FormControlCustom label="Loại sản phẩm" fullWidth>
                <div className="view-input">
                  <SelectCustom
                    options={dataType?.data || []}
                    placeholder={'Tất cả'}
                    name={'type'}
                    optionLabelKey="value"
                  />
                </div>
              </FormControlCustom>
              <FormControlCustom label="Thời gian" fullWidth>
                <div className="view-input add" style={{ width: '200px' }}>
                  <SelectCustom options={filterDateTime} placeholder={'Tất cả'} name={'time'} />
                </div>
              </FormControlCustom>
            </Box>
          </Grid>
          <Grid md={3}>
            <FormControlCustom label="Giá" fullWidth>
              <SliderPrice onSliderChange={onSliderChange} />
            </FormControlCustom>
          </Grid>
          <Grid md={3}>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <span className="txt-find" style={{ flexShrink: 0 }}>
                Tìm kiếm
              </span>
              <SearchInput
                className="txt-search"
                placeholder={'Nhập thông tin tìm kiếm'}
                value={searchValue}
                setSearchValue={setSearchValue}
                handleSearch={handleSearch}
              />
            </Box>
          </Grid>
        </Grid>
      </FormProvider>
      <div className="container-table">
        {(isMobile || screenWidth <= 650) && (
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              paddingBottom: '5px',
              flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '-8px' }}>
              <span
                style={{
                  width: '173px',
                  paddingLeft: '7px',
                  fontSize: '0.875rem',
                  textAlign: 'end',
                  whiteSpace: 'nowrap',
                }}
              >
                {sort}
              </span>
              <Box sx={{ marginRight: '5px' }}>
                <IconButton
                  size="small"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  className={'icon-toggle'}
                >
                  <ArrowDropDownIcon />
                </IconButton>
                {renderMenu}
              </Box>
            </div>
            <div>
              <span className="title-price" style={{ paddingLeft: '7px' }}>
                Tổng giá vốn:{' '}
              </span>
              <span className="txt-price">{currencyFormat(dataProduct?.data?.meta?.totalPrice || 0, '')}</span>
            </div>
          </Grid>
        )}
        <Grid style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          {buildTab()}
          {!isMobile && screenWidth > 650 && (
            <>
              <span
                style={{
                  width: '173px',
                  paddingLeft: '7px',
                  fontSize: '0.875rem',
                  textAlign: 'end',
                  whiteSpace: 'nowrap',
                }}
              >
                {sort}
              </span>
              <Box sx={{ marginRight: '5px' }}>
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  className={'icon-toggle'}
                >
                  <ArrowDropDownIcon />
                </IconButton>
                {renderMenu}
              </Box>
              <span
                className="title-price"
                style={{
                  whiteSpace: 'nowrap',
                  paddingLeft: '7px',
                }}
              >
                Tổng giá vốn:{' '}
              </span>
              <span className="txt-price">{currencyFormat(dataProduct?.data?.meta?.totalPrice || 0, '')}</span>
            </>
          )}
        </Grid>
        {value === 3 ? (
          <RefundList
            data={dataRefund?.data?.data}
            handleSelectionModeChange={handleSelectAllClick}
            handleChangePage={handleChangePageRefund}
            onChangeRowsPerPage={handleChangeRowsPerPageRefund}
            total={dataRefund?.data?.total}
            page={pageRefund}
            pageSize={pageSizeRefund}
            onApprove={onApproveRefund}
            refetchRefund={refetchRefund}
          />
        ) : (
          <CollapsibleTable
            columns={user?.roles?.includes(1) ? columns : columnsNoPermission}
            rows={dataProduct?.data?.data || []}
            selectedRow={handleSelectRow}
            onEditNote={onEditNote}
            selectedChildRow={onSelectedChildRow}
            selectedItemChild={selectedIdChildRow}
            total={dataProduct?.data?.total}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page || 0}
            pageSize={pageSize || 0}
            onShowDetail={onUpdateStock}
            changeTextNote={textNote}
            text={onChangeText}
            handelShowUpdateSku={handelShowUpdateSku}
            selected={selectedIdChildRow}
            skuProductId={skuIdProduct}
            onSelectAllClick={handleSelectAllClick}
          />
        )}
      </div>
      <div>
        <CreateStock
          setShowDrawer={setShowDrawer}
          showDrawer={showDrawer}
          refetch={refetch}
          dataProduct={detailProduct?.data?.data}
          type={formType}
        />
      </div>
      <SKUCreateAndEdit
        setShowDrawer={setShowSKUDrawer}
        showDrawer={showSKUDrawer}
        dataSku={detailSku?.data?.data}
        type={'update'}
        refetch={refetch}
      />
      <Menu
        id="menu-date-picker-range"
        // anchorEl={anchorElDateRange}
        open={Boolean(anchorElDateRange)}
        onClose={() => setAnchorElDateRange(false)}
        PaperProps={{ sx: { marginTop: '10px', width: '350px' } }}
        className={'calendarView'}
        sx={{
          '--top': `${document?.getElementsByClassName('add')[0]?.getBoundingClientRect()?.bottom}px`,
          '--left': `${document?.getElementsByClassName('add')[0]?.getBoundingClientRect()?.left}px`,
        }}
      >
        <Calendar
          // value={dateNow}
          onChange={changeDate}
          selectRange={true}
          prev2Label={null}
          next2Label={null}
          nextLabel={<ArrowForwardIosIcon sx={{ height: '20px', width: '20px' }} />}
          prevLabel={<ArrowBackIosIcon sx={{ height: '20px', width: '20px' }} />}
          navigationLabel={({ date }) => (
            <div className={styles.label}>{`${moment(date).locale('vi').format('MMMM, YYYY')}`}</div>
          )}
        />
      </Menu>
      <SnackBarCustom open={open} onClose={() => setOpen(false)} message={'Thêm thành công'} type={'success'} />
      <ModalAlert
        open={openModal}
        handleClose={() => handleCloseModal()}
        handleCancel={() => handleCloseModal()}
        handleConfirm={onConfirmDiscount}
        title={'Giảm giá'}
        renderContentModal={
          <div className="view-input-discount">
            <TextField
              type={'number'}
              placeholder={'0'}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: 100 }}
              style={{
                display: 'flex',
              }}
              className="input-discount"
              onChange={handleChangeDiscount}
              defaultValue={selectedRowData?.length > 1 ? '' : selectedRowData?.[0]?.discountValue}
              sx={{
                'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                  WebkitAppearance: 'none',
                  margin: 0,
                },
              }}
              onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
            />
            <span style={{ position: 'absolute', right: '25px' }}>%</span>
          </div>
        }
        type={'information'}
      />
    </div>
  );
};

export default StockManager;
