import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, Drawer, Grid } from '@mui/material';

import { useMutation, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import AutocompleteCustom from 'src/shared-components/AutocompleteCustom';
import FormControlCustom from 'src/shared-components/FormControlCustom';
import InputField from 'src/shared-components/InputField';

import * as yup from 'yup';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { checkStatusShipping } from 'src/functions/order-statuts';
import { getCity, getDelivery, getDistricts } from 'src/services/app.services';
import { getTracking, updateOrderTracking } from 'src/services/order.servies';
import { getProduct } from 'src/services/stock.services';
import useDebounce from 'src/shared-components/debounce';
import SelectCustom from 'src/shared-components/SelectCustom';
import { useToast } from 'src/shared-components/Toast';
import { currencyMark, numberFormat } from 'src/utils/utils';
import '../../../layout/defaultLayout.scss';

interface ICreateProps {
  setShowUpdateDrawer: React.Dispatch<
    React.SetStateAction<{
      type: 'refund' | 'delivery';
      isOpen: boolean;
    }>
  >;
  showUpdateDrawer: boolean | undefined;
  productList: any;
  data: any;
  address?: any;
  refetch: any;
  isCanEdit?: boolean;
}
const phoneRegExp = /(84|0[3|5|7|8|9|00])+([0-9]{8,12})\b/g;

const UpdateTracking = (props: ICreateProps) => {
  const { setShowUpdateDrawer, showUpdateDrawer, data, productList, address, refetch, isCanEdit = false } = props;
  const toast = useToast();

  const dataOrder = data;

  const { data: deliveryData } = useQuery(['delivery'], () => getDelivery({}));
  const { data: cityData } = useQuery(['city'], () => getCity({}));

  const [selected, setSelected] = useState<readonly string[]>([]);
  const [filterParams, setFilterParams] = useState<any>({ status: 'IN_PROCESS' });
  const [productTracking, setProductTracking] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<any>();
  const [trackingList, setTrackingList] = useState<any>([]);
  const debouncedSearch = useDebounce(searchValue, 500);

  const long = dataOrder?.dimensionLong?.toString();
  const wide = dataOrder?.dimensionWide?.toString();
  const High = dataOrder?.dimensionHigh?.toString();
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const schema = yup.object().shape({
    name: yup
      .string()
      .typeError('Tên khách hàng không được phép bỏ trống')
      .required('Tên khách hàng không được phép bỏ trống'),
    address: yup.string().typeError('Địa chỉ không được phép bỏ trống').required('Địa chỉ không được phép bỏ trống'),
    // email: yup.string().required('Email không được phép bỏ trống').nullable(),
    phone: yup
      .string()
      .required('Nhập số điện thoại')
      .matches(phoneRegExp, 'Số điện thoại không hợp lệ')
      .min(10, 'Số điện thoại không hợp lệ')
      .max(12, 'Số điện thoại không hợp lệ'),
    weight: yup.string().required('Vui lòng nhập khối lượng'),
    deliveryCOD: yup.string().required('Vui lòng nhập cod'),
    deliveryFreeCustomer: yup.string().required('Vui lòng nhập phí trả khách hàng'),
    deliveryFree: yup.string().required('Vui lòng nhập phí shipper'),
    deliveryTrucking: yup.lazy(value => {
      const delivery = methods.getValues()?.deliveryId?.code ?? '';
      if (delivery === '8') {
        return yup.string().nullable();
      }
      switch (typeof value) {
        case 'object':
          return yup.object().typeError('Vui lòng chọn mã vận đơn ').required('Vui lòng chọn mã vận đơn');
        case 'string':
          return yup.string().typeError('Vui lòng chọn mã vận đơn ').required('Vui lòng chọn mã vận đơn');
        default:
          return yup.mixed().typeError('Vui lòng chọn mã vận đơn ').required('Vui lòng chọn mã vận đơn');
      }
    }),
    deliveryId: yup.object().typeError('Vui lòng chọn đơn vị').required('Vui lòng chọn đơn vị'),
  });

  const defaultValues: any = useMemo(
    () => ({
      name: dataOrder?.shipping?.customerAddress?.name || '',
      city: dataOrder?.shipping?.customerAddress?.citiesId
        ? cityData?.data.find((item: any) => item.id === dataOrder?.shipping?.customerAddress?.citiesId)
        : '',
      address: dataOrder?.shipping?.customerAddress?.address || '',
      district: dataOrder?.shipping?.customerAddress?.districtId || '',
      email: dataOrder?.shipping?.customerAddress?.email || '',
      phone: dataOrder?.shipping?.customerAddress?.phone || '',
      note: dataOrder?.shipping?.note || '',
      deliveryTrucking: dataOrder?.shipping?.deliveryTrucking || '',
      deliveryFree: dataOrder?.deliveryFree || '',
      deliveryFreeCustomer: dataOrder?.deliveryFreeCustomer || '',
      deliveryCOD: dataOrder?.deliveryCOD || '',
      deliveryId: dataOrder?.shipping?.deliveryId
        ? deliveryData?.data.find((item: any) => item?.id === dataOrder?.shipping?.deliveryId)
        : null,
      weight: dataOrder?.weight || '',
      dimensionLong: dataOrder?.dimensionLong,
      dimensionWide: dataOrder?.dimensionWide,
      dimensionHigh: dataOrder?.dimensionHigh,
      dimension: long?.concat(',', wide)?.concat(',', High) || '',
    }),
    [dataOrder],
  );

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, formState, setValue, watch, getValues } = methods;
  const { errors } = formState;
  const cityWatch = watch('city');
  const deliveryTruckingWatch = watch('deliveryTrucking');
  const deliveryFreeWatch = watch('deliveryFree');
  const deliveryFreeCustomerWatch = watch('deliveryFreeCustomer');
  const deliveryCODWatch = watch('deliveryCOD');
  const weightWatch = watch('weight');
  const getValueCity = getValues('city');
  const dimensionWatch = watch('dimension');

  const { data: product } = useQuery(['product'], (params: any) => getProduct({ page: 1, pageSize: 10 }));
  const [cityID, setCityId] = useState<any>(dataOrder?.customerAddress?.cities?.id);
  const { deliveryTrucking } = watch();

  const { data: districtData } = useQuery(['district', getValueCity?.id], () => getDistricts(getValueCity?.id), {
    enabled: !!getValueCity?.id,
  });
  const { data: truckingData } = useQuery(['trucking', { ...filterParams }], () => getTracking({ ...filterParams }));

  const mutationUpdateOrderTracking = useMutation({
    mutationFn: updateOrderTracking,
    onSuccess: () => {
      toast('success', 'Thêm shipping thành công');
      refetch();
      goBack();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message || 'Thất bại');
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  useEffect(() => {
    const findDistrict = districtData?.data?.find(
      (item: any) => item?.id === dataOrder?.shipping?.customerAddress?.districtId,
    );
    setValue('district', findDistrict);
  }, [cityWatch]);

  useEffect(() => {
    if (!isEmpty(deliveryTruckingWatch?.id)) {
      setValue('name', deliveryTruckingWatch?.customerAddress?.name);
      setValue('city', deliveryTruckingWatch?.customerAddress?.cities);
      setValue('email', deliveryTruckingWatch?.customerAddress?.email || '');
      setValue('phone', deliveryTruckingWatch?.customerAddress?.phone);
      setValue('note', deliveryTruckingWatch?.customerAddress?.note);
    }
    if (!isEmpty(deliveryTruckingWatch)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [deliveryTruckingWatch]);
  useEffect(() => {
    const dimension = dimensionWatch.split(',');

    setValue('deliveryFree', currencyMark(deliveryFreeWatch));
    setValue('deliveryFreeCustomer', currencyMark(deliveryFreeCustomerWatch));
    setValue('deliveryCOD', currencyMark(deliveryCODWatch));
    setValue('weight', currencyMark(weightWatch.toString()));
    setValue('dimensionHigh', dimension[2]);
    setValue('dimensionLong', dimension[0]);
    setValue('dimensionWide', dimension[1]);
  }, [deliveryFreeWatch, deliveryFreeCustomerWatch, deliveryCODWatch, weightWatch, dimensionWatch]);

  useEffect(() => {
    let productTmp: any = [];
    productList?.map((prod: any) => {
      if (prod?.status !== 'IN_DELIVERY') {
        productTmp.push(prod);
      } else {
        setProductTracking([]);
      }
    });
    setProductTracking(productTmp);
  }, [productList]);

  useEffect(() => {
    const city: any = cityWatch;
    if (city) {
      setCityId(city?.id);
    }
  }, [cityWatch]);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [data]);

  useEffect(() => {
    if (!isEmpty(truckingData?.data?.data)) {
      let tracking = [...truckingData?.data?.data];
      setTrackingList(tracking);
    } else {
      let tracking: any = [];
      setTrackingList(tracking);
    }
  }, [truckingData]);

  useEffect(() => {
    setFilterParams({
      ...filterParams,
      deliveryTrucking: debouncedSearch?.data,
      status: 'IN_PROCESS',
    });
  }, [debouncedSearch]);

  const goBack = () => {
    reset();
    setShowUpdateDrawer(pre => ({ ...pre, isOpen: false }));
  };

  const toggleDrawer = (open: any) => (event: any) => {
    setShowUpdateDrawer(open);
  };

  const onChangeCustomer = (data: any) => {
    setSearchValue({
      data,
    });
  };

  const onSubmit = (values: any) => {
    const params = {
      data: {
        deliveryFree: numberFormat(values?.deliveryFree),
        deliveryFreeCustomer: numberFormat(values?.deliveryFreeCustomer),
        deliveryCOD: numberFormat(values.deliveryCOD),
        weight: numberFormat(values?.weight),
        dimensionLong: values.dimensionLong ? Number(values.dimensionLong) : undefined,
        dimensionWide: values?.dimensionWide ? Number(values?.dimensionWide) : undefined,
        dimensionHigh: values?.dimensionHigh ? Number(values?.dimensionHigh) : undefined,
        note: values?.note || ' ',
        user: user?.userName || ' ',
      },
      id: {
        orderId: dataOrder?.orderId,
        trackingId: dataOrder?.id,
      },
    };
    mutationUpdateOrderTracking.mutate(params);
  };

  const a = useCallback(
    (option: any, props: any, state: any) => {
      const checkBackground = () => {
        if (state?.index % 2 !== 0) {
          return { background: '#f5f7fa' };
        } else {
          return { background: 'white' };
        }
      };
      return (
        <>
          <div style={option.id === 0 ? {} : { backgroundColor: checkBackground().background }}>
            <div style={{ paddingBottom: '-2px', paddingTop: '-2px', display: 'flex' }} {...props}>
              <div style={{ width: '60%', justifyContent: 'center', display: 'flex' }}>
                <span> {option?.deliveryTrucking}</span>
              </div>
              <div style={{ width: '40%' }}>
                <span>{checkStatusShipping(option?.status).status}</span>
              </div>
            </div>
          </div>
        </>
      );
    },
    [undefined],
  );

  return (
    <Drawer
      PaperProps={{
        sx: { width: '40%', minWidth: '38rem' },
      }}
      anchor={'right'}
      open={showUpdateDrawer}
      className="drawer"
      onClose={toggleDrawer(false)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title-drawer">
            <div className="btn-close" onClick={goBack}>
              <ArrowBackIosIcon className="icon-back" />
            </div>
            <div>
              <span>Chỉnh sửa {['đơn vận chuyển', 'đơn hoàn hàng'][+isCanEdit]}</span>
            </div>
          </div>
          <div className="content-drawer">
            <div className="title-group">
              <span>Thông tin vận chuyển</span>
            </div>
            <div className="content">
              <Grid container spacing={1}>
                <Grid item xs={6} className="auto-complete">
                  <FormControlCustom label={'Người nhận'} fullWidth>
                    <InputField
                      name={'name'}
                      placeholder={''}
                      error={Boolean(errors.name)}
                      helperText={errors?.name?.message as string}
                      disabled={!isCanEdit}
                      className={'disabled-field'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Địa chỉ'} fullWidth>
                    <InputField
                      name={'address'}
                      helperText={errors?.address?.message as string}
                      error={Boolean(errors.address)}
                      placeholder={''}
                      className={'disabled-field'}
                      disabled={!isCanEdit}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Điện thoại'} fullWidth>
                    <InputField
                      name={'phone'}
                      helperText={errors?.phone?.message as string}
                      error={Boolean(errors?.phone)}
                      placeholder={'0'}
                      disabled={!isCanEdit}
                      className={'disabled-field'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Tỉnh/Thành'} fullWidth>
                    <SelectCustom
                      name={'city'}
                      placeholder={''}
                      error={Boolean(errors?.city)}
                      helperText={errors?.city?.message as string}
                      options={cityData?.data || []}
                      optionLabelKey={'name'}
                      disabled={!isCanEdit}
                      className={'disabled-field'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Email'} fullWidth>
                    <InputField
                      name={'email'}
                      helperText={errors?.email?.message as string}
                      error={Boolean(errors.email)}
                      placeholder={''}
                      disabled={!isCanEdit}
                      className={'disabled-field'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Quận/Huyện'} fullWidth>
                    <SelectCustom
                      name={'district'}
                      placeholder={''}
                      options={districtData?.data || []}
                      optionLabelKey={'name'}
                      disabled={!isCanEdit}
                      className={'disabled-field'}
                    />
                  </FormControlCustom>
                </Grid>

                <Grid item xs={6}>
                  <FormControlCustom label={'Ghi chú'} fullWidth>
                    <InputField
                      name={'note'}
                      helperText={errors?.note?.message as string}
                      error={Boolean(errors.note)}
                      placeholder={''}
                      rows={3}
                      multiline={true}
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
            <div className="title-group">
              <span>Đơn vị vận chuyển</span>
            </div>
            <div className="content">
              <Grid container spacing={1}>
                <Grid item xs={6} className="auto-complete">
                  <FormControlCustom label={'Đơn vị'} fullWidth>
                    <SelectCustom
                      options={deliveryData?.data}
                      placeholder={''}
                      name={'deliveryId'}
                      error={Boolean(errors?.deliveryId)}
                      helperText={errors?.deliveryId?.message as string}
                      className={'disabled-field'}
                      disabled={!isCanEdit}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Mã vận đơn'} fullWidth>
                    <AutocompleteCustom
                      style={{ width: '100%' }}
                      name={'deliveryTrucking'}
                      placeholder={'Tìm mã vận đơn'}
                      error={Boolean(errors?.deliveryTrucking)}
                      helperText={errors?.deliveryTrucking?.message as string}
                      options={trackingList || []}
                      renderOption={a}
                      onChangeValue={onChangeCustomer}
                      optionLabelKey={'deliveryTrucking'}
                      freeSolo
                      isAutoSelected={true}
                      disabled={!isCanEdit}
                      className={'disabled-field'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Phí ship khách hàng trả'} fullWidth>
                    <InputField
                      name={'deliveryFreeCustomer'}
                      helperText={errors?.deliveryFreeCustomer?.message as string}
                      error={Boolean(errors.deliveryFreeCustomer)}
                      placeholder={'0'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Tiền thu hộ COD'} fullWidth>
                    <InputField
                      name={'deliveryCOD'}
                      helperText={errors?.deliveryCOD?.message as string}
                      error={Boolean(errors.deliveryCOD)}
                      placeholder={'0'}
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
            <div className="title-group">
              <span>Thông tin gói hàng</span>
            </div>
            <div className="content">
              <Grid container spacing={1}>
                <Grid item xs={6} className="auto-complete">
                  <FormControlCustom label={'Khối lượng(kg)'} fullWidth>
                    <InputField
                      name={'weight'}
                      placeholder={'0'}
                      error={Boolean(errors.weight)}
                      helperText={errors?.weight?.message as string}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Kích thước'} fullWidth>
                    <InputField
                      name={'dimension'}
                      helperText={errors?.address?.message as string}
                      error={Boolean(errors.address)}
                      placeholder={'dài, rộng, cao'}
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className="footer-drawer">
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className="btn-secondary-disable" onClick={goBack}>
                  Quay lại
                </Button>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className={'btn-tertiary-normal'} type="submit">
                  Lưu thông tin
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default UpdateTracking;
