import * as yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { Button, Drawer, Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import InputField from 'src/shared-components/InputField';
import { useMutation, useQuery } from '@tanstack/react-query';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import '../../../layout/defaultLayout.scss';
import clientRequest from 'src/config/axios';
import { useToast } from 'src/shared-components/Toast';
import SelectCustom from 'src/shared-components/SelectCustom';
import FormControlCustom from 'src/shared-components/FormControlCustom';
import { getBank, getCity, getDistricts } from 'src/services/app.services';
import { subgroups } from 'src/utils/app-data';
import { LoadingButton } from '@mui/lab';

interface ICreateProps {
  data?: any;
  type?: string;
  refetch?: any;
  customerCreateForm?: any;
  showDrawer: boolean | undefined;
  setShowDrawer: (data: any | undefined) => void;
}
const phoneRegExp = /(84|0[3|5|7|8|9|00])+([0-9]{8,12})\b/g;

const AddCustomer = (props: ICreateProps) => {
  const { setShowDrawer, showDrawer, customerCreateForm, data, type, refetch } = props;
  const toast = useToast();

  const { data: bankData } = useQuery(['bank'], () => getBank({}));

  const schema = yup.object().shape({
    name: yup
      .string()
      .typeError('Tên khách hàng không được phép bỏ trống')
      .required('Tên khách hàng không được phép bỏ trống'),
    address: yup.string().typeError('Địa chỉ không được phép bỏ trống').required('Địa chỉ không được phép bỏ trống'),
    email: yup.string(),
    phone: yup
      .string()
      .required('Nhập số điện thoại')
      .matches(phoneRegExp, 'Số điện thoại không hợp lệ')
      .min(10, 'Số điện thoại không hợp lệ')
      .max(12, 'Số điện thoại không hợp lệ'),
    district: yup.object().typeError('Vui lòng chọn quận huyện').required('Vui lòng chọn quận huyện'),
    city: yup.object().typeError('Vui lòng chọn tỉnh / thành phố').required('Vui lòng chọn tỉnh / thành phố'),
    group: yup.object().typeError('Vui lòng chọn nhóm').required('Vui lòng chọn nhóm'),
  });

  const defaultValues: any = useMemo(
    () => ({
      name: data?.customerAddress?.name || '',
      city: data?.customerAddress?.cities || '',
      address: data?.customerAddress?.address || '',
      district: data?.customerAddress?.district || '',
      email: data?.customerAddress?.email || '',
      phone: data?.customerAddress?.phone || '',
      note: data?.note || '',
      placePickOrder: data?.placePickOrder
        ? bankData?.data?.orderPlaces.find((item: any) => item?.name === data?.placePickOrder)
        : '',
      group: data?.customerAddress?.groupId,
    }),
    [data],
  );

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, formState, setValue, watch } = methods;
  const { errors } = formState;
  const cityWatch = watch('city');
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const { data: cityData } = useQuery(['city'], () => getCity({}));
  const [cityID, setCityId] = useState<any>();
  const { data: detailDistrict } = useQuery(['district', cityID], () => getDistricts(cityID), {
    enabled: !!cityID,
  });

  const mutationStock = useMutation({
    mutationFn: (params: any) => {
      if (type === 'update') {
        return clientRequest.patch(`orders/${data?.id}/customerAddress`, {
          ...params,
        });
      } else {
        return clientRequest.post(`orders/customer`, {
          ...params,
        });
      }
    },
    onSuccess: (data: any) => {
      toast('success', type === 'update' ? 'Thay đổi thành công' : 'Thêm mới thành công');
      goBack();
      type !== 'update' && customerCreateForm(data?.data);
      reset({ ...defaultValues });
      refetch();
    },
    onError: (error: any) => {
      if (error.response.status == 403) {
        toast('error', 'Bạn không có quyền thực hiện');
      } else {
        toast('error', error.response.data.message);
      }
    },
    onSettled: () => {
      refetch();
    },
  });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [data]);

  useEffect(() => {
    const city: any = cityWatch;
    if (city) {
      setCityId(city?.id);
    }

    // setValue('district', []);
  }, [cityWatch]);

  const goBack = () => {
    reset();
    setShowDrawer(false);
  };

  const toggleDrawer = (open: any) => (event: any) => {
    setShowDrawer(open);
  };

  const onSubmit = (value: any) => {
    const params = {
      name: value?.name,
      address: value?.address,
      phone: value?.phone,
      email: value?.email,
      citiesId: value?.city.id,
      districtId: value?.district?.id,
      placePickOrder: type === 'update' ? value?.placePickOrder?.name : undefined,
      note: value?.note,
      groupId: value?.group?.id,
      user: user?.userName,
    };
    mutationStock.mutate(params);
  };

  const getTitle = (type: any) => {
    if (type === 'update') {
      return 'Thay đổi';
    } else {
      return 'Thêm khách hàng';
    }
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: '40%' },
      }}
      anchor={'right'}
      open={showDrawer}
      className="drawer"
      onClose={toggleDrawer(false)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="title-drawer">
            <div className="btn-close" onClick={goBack}>
              <ArrowBackIosIcon className="icon-back" />
            </div>
            <div>
              <span>{getTitle(type)}</span>
            </div>
          </div>
          <div className="content-drawer">
            <div className="title-group">
              <span>Thông tin khách hàng</span>
            </div>
            <div className="content">
              <Grid container spacing={1.5}>
                <Grid item xs={6} className="auto-complete">
                  <FormControlCustom label={'Khách hàng'} fullWidth>
                    <InputField
                      name={'name'}
                      placeholder={''}
                      error={Boolean(errors.name)}
                      helperText={errors?.name?.message as string}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Địa chỉ'} fullWidth>
                    <InputField
                      name={'address'}
                      helperText={errors?.address?.message as string}
                      error={Boolean(errors.address)}
                      placeholder={''}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Tỉnh/Thành phố'} fullWidth>
                    <SelectCustom
                      name={'city'}
                      placeholder={''}
                      error={Boolean(errors?.city)}
                      helperText={errors?.city?.message as string}
                      options={cityData?.data || []}
                      optionLabelKey={'name'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Email'} fullWidth>
                    <InputField
                      name={'email'}
                      helperText={errors?.email?.message as string}
                      error={Boolean(errors.email)}
                      placeholder={''}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Quận/Huyện'} fullWidth>
                    <SelectCustom
                      name={'district'}
                      placeholder={'Lựa chọn'}
                      error={Boolean(errors?.district)}
                      helperText={errors?.district?.message as string}
                      options={detailDistrict?.data || []}
                      optionLabelKey={'name'}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Điện thoại'} fullWidth>
                    <InputField
                      name={'phone'}
                      helperText={errors?.phone?.message as string}
                      error={Boolean(errors?.phone)}
                      placeholder={'0'}
                    />
                  </FormControlCustom>
                </Grid>

                {type === 'update' && (
                  <Grid item xs={6}>
                    <FormControlCustom label={'Nơi chốt đơn'} fullWidth>
                      <SelectCustom
                        name={'placePickOrder'}
                        helperText={errors?.price?.message as string}
                        error={Boolean(errors.price)}
                        placeholder={''}
                        optionLabelKey={'name'}
                        options={bankData?.data?.orderPlaces || []}
                      />
                    </FormControlCustom>
                  </Grid>
                )}

                <Grid item xs={6}>
                  <FormControlCustom label={'Ghi chú'} fullWidth>
                    <InputField
                      name={'note'}
                      helperText={errors?.price?.message as string}
                      error={Boolean(errors.price)}
                      placeholder={''}
                    />
                  </FormControlCustom>
                </Grid>
                <Grid item xs={6}>
                  <FormControlCustom label={'Phân nhóm'} fullWidth>
                    <SelectCustom
                      name={'group'}
                      placeholder={'Lựa chọn'}
                      error={Boolean(errors?.group)}
                      helperText={errors?.group?.message as string}
                      options={subgroups || []}
                      optionLabelKey={'name'}
                    />
                  </FormControlCustom>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className="footer-drawer">
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item xs={6} display="flex" justifyContent="end">
                <Button className="btn-secondary-disable" onClick={goBack}>
                  Quay lại
                </Button>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="end">
                <LoadingButton
                  loadingPosition="start"
                  loading={mutationStock.isLoading}
                  disabled={mutationStock.isLoading}
                  className={'btn-tertiary-normal'}
                  type="submit"
                >
                  Lưu thông tin
                </LoadingButton>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
    </Drawer>
  );
};

export default AddCustomer;
